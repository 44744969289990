var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "inspectManagerTaskForm-wrapper" },
    [
      _c(
        "form-panel",
        { ref: "formPanel" },
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "巡查信息" } },
                [
                  _c("col2-item", {
                    attrs: {
                      label: "巡查类型",
                      text: _vm.typeMap[_vm.detailItem.inspectType],
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "巡查人",
                      text: _vm.detailItem.inspectUsername,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "计划巡查时间",
                      text: _vm.detailItem.planEndTime,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "完成地点",
                      text: _vm.detailItem.finishAddress,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "巡查完成时间",
                      text: _vm.detailItem.realEndTime,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "巡查结果",
                      text: _vm.resultMap[_vm.detailItem.result],
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "巡查方式",
                      text: _vm.inspectMethodMap[_vm.detailItem.inspectMethod],
                    },
                  }),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "巡查要点" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "el-form-wrapper",
                      attrs: { label: "巡查点分类" },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.detailItem.stageName))]),
                      _vm._l(_vm.detailItem.orderInfo, function (item, index) {
                        return _c("div", { key: index }, [
                          _c("div", { staticClass: "content-wrapper" }, [
                            _c("span", { staticClass: "left-text" }, [
                              _vm._v(_vm._s(index + 1 + "、" + item.content)),
                            ]),
                            _c("span", { staticClass: "middle-text" }, [
                              _vm._v("巡查说明：" + _vm._s(item.result)),
                            ]),
                            item.serialNumber.length
                              ? _c(
                                  "div",
                                  [
                                    _c("v-button", {
                                      attrs: {
                                        type: "text",
                                        text: item.serialNumber,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.orderClick(item)
                                        },
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        "\n                  (" +
                                          _vm._s(item.orderStatus) +
                                          ")\n                  "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                  _c("col2-item", { attrs: { span: 24, label: "巡查照片" } }, [
                    _c(
                      "div",
                      [_c("v-preview", { attrs: { imgs: _vm.imageUrls } })],
                      1
                    ),
                  ]),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "巡查描述",
                      text: _vm.detailItem.remark,
                    },
                  }),
                ],
                1
              ),
              _vm.detailItem.progressList.length
                ? _c(
                    "col2-block",
                    { attrs: { title: "处理进度" } },
                    _vm._l(_vm.detailItem.progressList, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "deal-progress" },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(item.type == 1 ? "转派处理" : "处理完成") +
                                "：" +
                                _vm._s(item.userName)
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              _vm._s(item.type == 1 ? "转派时间" : "完成时间") +
                                "：" +
                                _vm._s(item.initTime)
                            ),
                          ]),
                          item.type == 1
                            ? _c("div", [
                                _vm._v(
                                  "被转派人：" + _vm._s(item.userNameAssigned)
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }