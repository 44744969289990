<!--
 * @Description: 巡查管理--巡查执行情况--表单页面
 * @Author: 小广
 * @Date: 2019-05-29 18:33:57
 * @LastEditors: 小广
 * @LastEditTime: 2019-08-29 16:32:23
 -->
<template>
  <div class="inspectManagerTaskForm-wrapper">
    <form-panel ref="formPanel">
      <col2-detail>
        <col2-block title="巡查信息">
          <col2-item label="巡查类型" :text="typeMap[detailItem.inspectType]" />
          <col2-item label="巡查人" :text="detailItem.inspectUsername" />
          <col2-item label="计划巡查时间" :text="detailItem.planEndTime" />
          <col2-item label="完成地点" :text="detailItem.finishAddress" />
          <col2-item label="巡查完成时间" :text="detailItem.realEndTime" />
          <col2-item label="巡查结果" :text="resultMap[detailItem.result]" />
          <col2-item
            label="巡查方式"
            :text="inspectMethodMap[detailItem.inspectMethod]"
          />
        </col2-block>
        <col2-block title="巡查要点">
          <el-form-item class="el-form-wrapper" label="巡查点分类">
            <span>{{ detailItem.stageName }}</span>
            <div
              v-for="(item, index) in detailItem.orderInfo"
              :key="index" >
              <div class="content-wrapper">
                <span class="left-text">{{ index + 1 + '、' + item.content }}</span>
                <span class="middle-text">巡查说明：{{ item.result }}</span>
                <div v-if="item.serialNumber.length">
                  <v-button type="text" :text="item.serialNumber" @click="orderClick(item)" ></v-button>
                  <span>
                    ({{ item.orderStatus }})
                    <!-- <template v-if="detailItem.newOrder">
                      ({{newOrderStatusMap[item.orderStatus]}})
                    </template>
                    <template v-else>
                      【{{ orderStatusMap[item.orderStatus] }}】
                    </template> -->
                  </span>
                </div>
              </div>
            </div>
          </el-form-item>
          <col2-item :span="24" label="巡查照片">
            <div>
              <v-preview :imgs="imageUrls"></v-preview>
            </div>
          </col2-item>
          <col2-item :span="24" label="巡查描述" :text="detailItem.remark" />
        </col2-block>
        <col2-block title="处理进度" v-if="detailItem.progressList.length">
          <div class="deal-progress" v-for="(item, index) in detailItem.progressList":key="index">
            <div>{{item.type==1?'转派处理':'处理完成'}}：{{item.userName}}</div>
            <div>{{item.type==1?'转派时间':'完成时间'}}：{{item.initTime}}</div>
            <div v-if="item.type==1">被转派人：{{item.userNameAssigned}}</div>
          </div>          
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>

// 引用的类
import { getTaskDetailURL } from './api'
import { typeMap, inspectResultMap, patternMap, orderStatusMap, newOrderStatusMap } from './map.js'
import { Col2Block, Col2Item, Col2Detail } from 'components/bussiness'
import { vPreview } from 'components/control'

export default {
  name: '',
  /// 引用的组件处理
  components: {
    Col2Block,
    Col2Item,
    Col2Detail,
    vPreview
  },

  created () {
    // 设置title
    this.$setBreadcrumb('巡查日志详情')
  },
  // 加载详情数据
  mounted () {
    this.getDetailData()
  },

  /// 必有方法
  data () {
    return {
      typeMap: typeMap,
      resultMap: inspectResultMap,
      inspectMethodMap: this.getInspectMethodMap(),
      detailItem: {},
      imageUrls: [],
      orderStatusMap,
      newOrderStatusMap
    }
  },

  methods: {
    // 处理巡查方式map
    getInspectMethodMap () {
      let map = patternMap
      map['3'] = '非蓝牙'
      return map
    },

    // 工单编号点击的处理
    orderClick (order) { // TODO
      // 跳转工单3.0
      if (this.detailItem.newOrderV3) {
        const menuInfo = this.$store.getters['menuStore/getMenuInfo'](947241)
        this.$router.push({
          name:'iframePanel',
          query:{
            pid:1,
            cid:3400,
            fid:94751,
            sid:947241,
            src: menuInfo.item.url,
            orderId: order.orderId,
            isReplace: 1
          }
        })
      } else {
        // 跳转到工单页面
        if(this.detailItem.newOrder) {
          // 新工单
          this.$router.push({
            name: 'orderSchedulingForm',
            query: {
              id: order.orderId,
            }
          })
        }else {
          // 老工单
          this.$router.push({
            name: 'serviceSheetForm',
            query: {
              id: order.orderId,
              orderCode: order.serialNumber,
              communityId: this.detailItem.communityId,
              noEdit: '1'
            }
          })
        }
      }
      



    },

    /// 获取详情数据
    getDetailData () {
      let _this = this
      let getParames = {
        params: {
          id: this.$route.query.id
        }
      }
      let requestUrl = `${getTaskDetailURL}/${this.$route.query.id}`
      this.$axios.get(requestUrl, getParames).then(res => {
        if (res.status === 100) {
          _this.detailItem = res.data || {}
          // 处理下图片
          let imgUrls = res.data.imgUrls || []
          let imageUrls = []
          if (imgUrls.length) {
            imgUrls.forEach(item => {
              imageUrls.push({ src: item })
            })
          }
          _this.imageUrls = imageUrls
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.inspectManagerTaskForm-wrapper {
  .content-wrapper {
    display: flex;

    .left-text {
      width: 200px;
      margin-right: 100px;
    }

    .middle-text {
      width: 100px;
      margin-right: 100px;
    }
  }

  .el-form-wrapper {
    width: 100%;
  }

  .deal-progress{
    display: flex;
    padding: 8px 0 8px 86px;
    >div{
      width: 33.3%;
      justify-content: flex-start;
    }
  }
}
</style>
